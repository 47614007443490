@keyframes floating {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.react-floater-animated {
  display: flex;
  justify-content: center;
  animation: floating 3s ease infinite;
  will-change: transform;
}