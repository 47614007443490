.content-parent{
  margin: 0;
  padding: 0;
  height: 92vh;
  width: 100%;
  overflow: hidden;
  user-select: none;
  font-family: 'Raleway', sans-serif;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png') 39 39,
    auto;
}

.draggable-main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
  font-family: 'Raleway', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
}

#content {
  position: relative;
  width: 320px;
  height: 240px;
  margin-inline: auto;
}

#content > div {
  text-align: center;
  position: absolute;
  width: 320px;
  height: 90px;
  overflow: visible;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: white;
  line-height: 90px;
  /* padding-left: 32px; */
  font-size: 14.5px;
  background: lightblue;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 100px;
}

#content > div:nth-child(odd) {
  background: linear-gradient(135deg, #8f8f8f 10%, #000000 115%);
}
#content > div:nth-child(even) {
  background: linear-gradient(135deg, #000000 0%, #8f8f8f 100%);
}