* {
  box-sizing: border-box;
}

.aos-item {
  width: 300px;
  height: 300px;
  margin: 50px auto;
  padding-top: 75px;
  background: #ccc;
  text-align: center;
  color: #FFF;
  border-radius: 175px;
  font-size: 3em;
  position: relative;
}

.aos-item > p{
  margin-top: unset;
}

.aos-left{
  margin-left: 20%;
  background: linear-gradient(135deg,#000,#8f8f8f);
}

.aos-right{
  margin-right: 20%;
  background: linear-gradient(135deg,#8f8f8f 16%,#000 101%);
}