@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);

:root {
  --dark: #101315;
  --light: #eeeeee;
  --gradient: linear-gradient(10deg, #ffaa00, #ff6a00);
  --gradient2: linear-gradient(15deg, #04ea00, #00d17d);
  --gradient3: linear-gradient(15deg, #b648ff, #ef5dff);
  --gradientGrey: linear-gradient(25deg,  #000000, #383838);
  font-size: 1rem;
}



* {
  box-sizing: border-box;
}


/*
body {
  background: var(--dark);
  font-family: "Montserrat", sans-serif;
  height: 100%;
  overflow-y: hidden;
}

main {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  overflow-y: hidden;
}

h1 {
  color: #ff9d00;
  font-size: 175%;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

h2 {
  font-weight: 500;
  font-size: 150%;
  letter-spacing: 0;
  font-family: "Montserrat", sans-serif;
}

h3 {
  text-align: center;
  color: var(--dark);
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  padding: 2rem 0 1.5rem 0;
  margin: 0;
  text-transform: capitalize;
}

h5 {
  text-align: center;
  color: var(--dark);
  font-weight: 500;
  font-size: 130%;
  text-align: justify;
  letter-spacing: 0;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  line-height: 125%;
  margin: 0;
}

kbd {
  background: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px #00000028, 0 2px 0 0 #ffffffc3 inset;
  color: #131313;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
  margin: auto;
} */
/* 
button {
  width: auto;
  height: 3rem;
  border: none;
  outline: none;
  -webkit-appearance: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 1.25px;
  cursor: default;
  font-family: "Montserrat", sans-serif;
} */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.modal {
  color: var(--light);
  position: unset;
  width: fit-content;
  height: min-content;
  max-height: 80vh;
  margin: 5vh;
  padding-bottom: 10px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orange-gradient {
  background: var(--gradient);
}

.green-gradient {
  background: var(--gradient2);
}

.grey-gradient {
  background: var(--gradientGrey);
}
.pink {
  color: #c273ff;
}

.gray {
  color: #666666;
}

.light-blue {
  color: #00b7ff;
}

.modal-button {
  position: relative;
  bottom: 1.5rem;
  padding: 0 3rem;
  min-height: 3rem;
  margin: auto auto 0 auto;
  background: var(--dark);
  color: var(--light);
}

.save-button {
  padding: 0 1rem;
  /* margin: 2rem auto auto 0; */
  background: var(--gradientGrey);
  color: var(--light);
  height: 3rem;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 1.25px;
  margin-block: 1rem;
}

.submit-button {
  padding: 0 2rem;
  height: 2.5rem;
  margin: 5px auto;
  background: var(--light);
  border: none;
  color: var(--dark);
  border-radius: 4px;
  transition: background ease 400ms;
  box-shadow: 1px 1px 15px #03030399;
}

.submit-button:hover{
  border: #00b7ff;
}

.submit-button:active{
  background-color: var(--gradientGrey)
}

.close-icon{
  position: absolute;
  right: 5px;
  top: 5px;
  height: 25px;
  cursor: pointer;
}


.input {
  width: calc(100vw - 5rem);
  height: 3rem;
  margin: 0 auto 0 0;
  padding: 0.25rem 0.5rem;
  outline: none;
  background: var(--dark);
  border: 2px solid #ff9d00;
  border-radius: 6px;
  color: #ff9d00;
  font-size: 1.25rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

::placeholder {
  font-style: italic;
}
/* 
.container {
  display: flex;
  width: 50vw;
  height: 50%;
  margin: auto;
} 

ul,
li {
  padding: 0;
  margin: 0;
}

ul {
  position: fixed;
  bottom: 0.5rem;
  right: 0;
  top: 0.5rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bottom {
  justify-content: flex-end;
}

.top {
  justify-content: flex-start;
} 

li {
  width: 225px;
  margin: 0.5rem 1.5rem;
  padding: 0 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
}

.notification-text {
  margin: auto auto auto 0;
  padding: 0;
  font-size: 100%;
  font-weight: 600;
  letter-spacing: 0.25px;
  font-family: "Montserrat", sans-serif;
}

.add-button {
  padding: 0 1rem;
  margin: 2rem auto auto 0;
  background: var(--gradient2);
  color: var(--dark);
}

.close {
  height: 1.1rem;
  background: transparent;
  border: none;
  outline: none;
  margin: 0 0 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close svg {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.sub-header {
  margin: 1rem auto 1rem 0;
  color: #9e9e9e;
}



@media screen and (min-width: 960px) {
  button {
    cursor: pointer;
  }
  .modal {
    width: 750px;
    height: 300px;
  }
  .input,
  input {
    width: calc(25vw);
  }
  h1 {
    color: #ff9d00;
    font-size: 250%;
  }
}
*/

