@font-face {
  font-family: 'Quicksand';
  src: url('/public/fonts/Quicksand-Light.woff2') format('woff2'),
      url('/public/fonts/Quicksand-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

.base :is(ul, h1, h2, h3, p, button, a, div){
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Quicksand';
  letter-spacing: 2px;
  list-style-type:none;
  text-decoration: none;
}

.base{
  /* background: rgb(100,0,123); */
  background: radial-gradient(circle,#000000 0,#2e2e2e 75%);
  overflow: hidden;
}

.base button{
  color: white;
  padding: 10px 30px;
  font-size: 1em;
  background: transparent;
  border-radius: 50px;
  border: 1px solid white;
  margin: 40px auto 0;
  cursor: pointer;
  opacity: 0.7;
}

.base .container{
  max-width: max-content;
  margin: 160px auto 160px;
}
.base .container h3{
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,0.2);
}
.base .container li{
  padding: 10px;
  cursor: pointer;
}

.base li span.active{
  font-weight: bold;
}
.base li span.active::before{
  content: '>';
  position: relative;
  top: -2px;
  margin-right: 6px;
  transform: scale(0.8, 1);
  display: inline-block;
}