.parallax{
  color: white;
}

.anzali-app-icon {
width: 20%;
position: relative;
bottom: 60px;

  @media (orientation:portrait){
    width: 80%;
  }

}

.description-div{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    width: 50%;
    background: rgb(255,255,255,0.1);
    border-radius: 20px;
    padding: 0 60px;

    @media (orientation:portrait) {
      width: 80%;
      padding: 0 5px;
    }
}