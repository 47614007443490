.App {
  /* text-align: center;
  background-color: #282c34;
  height: 100vh; */

  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGQ9Ik03MzEuMjA3IDY0OS44MDJDOTM1LjQ4NCA2NDIuMTQgMTQ4MCAzMzcuMzI1IDE0ODAgMTgwLjg4OGMwLTE1Ni40MzgtMzA5Ljc0NC0zNi4wNTUtNzIwLTM2LjA1NVMwLTE3NC40ODMgMCAxMzUuMTQ0YzAgMzA5LjYyNyA1MjYuOTMgNTIyLjMyIDczMS4yMDcgNTE0LjY1OHoiIGZpbGw9IiNGNkY4RkEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==") !important;
  background-color: #ffffff;
  background-size: cover !important;
  background-position-x: 20vw !important;
  background-position-y: -10vh !important;
  color: black;
  font-family: "Montserrat", sans-serif;
  /* min-height: 99vh; */
  /* text-align: center; */

  /* background-repeat: no-repeat !important; */
}

#top_container {
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;
}

.logo {
  /* display: inline-block; */
  /* position: relative;
  left: 30px;
  top: 10px; */
  border-radius: 22.5%;
  /* max-width: 250px;
  max-height: 250px; */
  padding: 1px;
  width: 100%;
  height: 100%;
}

.header {
  flex-direction: row;
  align-items: center;
  /* display: flex;
  margin-bottom: 128px;
  margin-top: 128px; */
}
/* 
.app__info__text{
  margin-left: 10px;
} */

@media (max-width: 576px) {
  .logo {
    max-width: 100px;
    max-height: 100px;
    margin-bottom: 5%;
  }

  .header {
    margin-top: 10px;
  }

  .app__info {
    text-align: center;
  }

  .app__info__text{
    justify-content: center;
  }

}

.app__info__text{
  margin-top: 20px!important;
}

.app__info {
  margin: 10% auto 5% auto;
}

.store__button img {
  width: 100%;
  transition: 0.2s;
  cursor: pointer;
}

.store__button img:hover {
  transform: scale(1.05);
  box-shadow: 0 0px 15px 0 rgb(255 255 255), 0 0px 30px 0 rgb(0 0 0);

}

#screenshots {
  margin: 5% auto 5% auto;
}

#screenshots img {
  width: 100%;
  border-radius: 15px;
  transition: transform 0.2s;
  cursor: pointer;
}


.zoom__icon{
  top: 10px;
  position: absolute;
  width: auto!important;
  right: 20px;
}

@media (min-width: 1024px){
  .zoom__icon{
    display: none;
  }
  
  #screenshots img:hover {
    transform: scale(1.15);
  }
}


.screenshots-scroll {
  overflow: auto !important;
  width: 100%;
  /* padding:1rem; */
}

.screenshots-scroll::-webkit-scrollbar{
  display: none;
}


#description{
  margin: 10% auto;
}

@media (min-width: 768px) {
  .screenshots-scroll {
    padding-block : 2rem;
    padding-inline: 1rem;
  }

  #screenshots h1{
    padding-left: 1rem!important;
  }

  #description {
    margin: 5% auto 5% 1rem!important;
  }

  .description-columns{
    padding-right: 3rem!important;
    padding-left: 3rem!important;
  }

  #description .mobileImage{
    padding-inline: 20%;
  }
  
  .video-play{
    width: 8% !important;
  }

  .video-thumbnail{
    margin-top: -8%;
  }
}

@media (max-width:767px) {
  #description .mobileImage{
    width: 120%!important;
    margin-bottom: -15%;
  }
}

.about_container{
  /* padding: 10%; */
  width: 320px !important;
  padding-inline: 10% !important;
  max-width: 720px !important;
}

@media (max-width: 350px){
  .about_container{
    width: 275px !important;
  }
}

.video-play {
  position: absolute;
    inset: 0px;
    width: 10%;
    margin: auto;
    transition: all 0.2s ease 0s;
    cursor: pointer;
}

.video-play:hover{
  /* box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 1), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  transform: scale(1.15);

}

.modal-video-inner {
  /* width: 70vh!important; */
  padding: 5px;
}


@media (max-width: 576px) and (orientation:portrait){
  .modal-video-close-btn{
    height: 35px;
    width: 15px;
    top: -25px;
  }

  .modal-video-inner {
    /* width: 70vh!important; */
    padding: 20px;
  }
}

@media (min-width: 576px) and (orientation:portrait){
  .modal-video-inner {
    /* width: 70vh!important; */
    padding: 20px;
  }
}

.modal-video-inner {
  max-width: 500px;
}